export function howSoWorks() {

    if (document.getElementById('how-so-button')) {

        document.getElementById('how-so-button').addEventListener('click', function () {
            var answer = document.getElementById('how-so-content');
            var arrow = document.getElementById('how-so-arrow');

            if (answer.style.display === 'none' || answer.style.display === '') {
                answer.style.display = 'block';
                arrow.style.transform = 'rotate(0deg)';
            } else {
                answer.style.display = 'none';
                arrow.style.transform = 'rotate(-180deg)';
            }
        });

    }
}


export function webtextHide() {

    if (document.getElementById('webtext')) {

        // Based on tech 3 https://css-tricks.com/using-css-transitions-auto-dimensions/
        const webtext = document.getElementById("webtext");
        const bottom_hide = document.getElementById("bottom_hide");
        const bottom_fade = document.getElementById('bottom_fade')

        webtext.addEventListener('click', function (e) {
            var sectionHeight = webtext.scrollHeight;
            webtext.style.height = sectionHeight + 'px';
            webtext.addEventListener('transitionend', function ({callee}) {
                webtext.removeEventListener('transitionend', callee);
                if (bottom_fade.classList.contains('hidden')) {
                    webtext.style.height = 'auto';
                }

            });
            bottom_fade.classList.add('hidden');
            bottom_hide.classList.remove('hidden');
        });

        bottom_hide.addEventListener("click", () => {

            var sectionHeight = webtext.scrollHeight;
            var elementTransition = webtext.style.transition;

            requestAnimationFrame(function () {
                webtext.style.height = sectionHeight + 'px';
                webtext.style.transition = elementTransition;

                requestAnimationFrame(function () {
                    webtext.style.height = 150 + 'px';
                });
            });

            bottom_fade.classList.remove('hidden');
            bottom_hide.classList.add('hidden');
        })

    }
}


export function menuToggle() {
    document.getElementById('nav-toggle').onclick = function () {
        document.getElementById("nav-content").classList.toggle("hidden");
    }
}


function isIE() {
    // IE 10 and IE 11
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
}

export function ieRedirect() {


    let is_IE = isIE();
    let path = window.location.pathname;

    if (is_IE && path.indexOf("update-browser") < 0) {
        window.location.assign("/update-browser")
    }


}
