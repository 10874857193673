import Vue from "vue";

export const store = Vue.observable({
    state: {
        pid: 0,
        destination: [],
        arrive: "",
        nights: 0,
        currentPopup: 0,
        locationLat: null,
        locationLng: null,
        locationNe: null,
        locationSw: null,
        locationNorthEastLat: null,
        locationNorthEastLong: null,
        locationSouthWestLat: null,
        locationSouthWestLong: null,
        results: null,
        unrestricted: false,
        filterShow: false,
        showPhotoswipe: false,
        contactOwnerShow: false,
        contactOwnerType: "website",
        leeCalendarStartDateFormatted: null,
        leeCalendarDuration: null,
        leeCalendarSelectedPrice: null
    }
});

export const mutations = {
    setLeeCalendarStartDateFormatted(date) {
        store.state.leeCalendarStartDateFormatted = date;
    },
    setLeeCalendarDuration(duration) {
        store.state.leeCalendarDuration = duration;
    },
    setLeeCalendarSelectedPrice(price) {
        store.state.leeCalendarSelectedPrice = price;
    },
    setDestination(array) {
        store.state.destination = array;
    },
    setPID(number) {
        store.state.pid = number;
    },
    updateUrl(url) {
        store.state.destination["0"].url = url;
    },
    setPopup(number) {
        store.state.currentPopup = number;
    },
    setLocationBounds(ne, sw) {
        store.state.locationNe = ne;
        store.state.locationSw = sw;
        let neArray = ne.split(",");
        let swArray = sw.split(",");
        store.state.locationNorthEastLat = neArray[0];
        store.state.locationNorthEastLong = neArray[1];
        store.state.locationSouthWestLat = swArray[0];
        store.state.locationSouthWestLong = swArray[1];
    },
    setLocationCentre(lat, lng) {
        store.state.locationLat = lat;
        store.state.locationLng = lng;
    },
    setResults(data) {
        store.state.results = data;
    },
    setNights(data) {
        store.state.nights = data;
    },
    setUnrestricted(data) {
        store.state.unrestricted = data;
    },
    toggleFilter() {
        store.state.filterShow = !store.state.filterShow;
    },
    togglePhotoswipe() {
        store.state.showPhotoswipe = !store.state.showPhotoswipe;
    },
    toggleContactOwner() {
        store.state.contactOwnerShow = !store.state.contactOwnerShow;
    },
    setContactOwnerType(string) {
        store.state.contactOwnerType = string;
    }
};
