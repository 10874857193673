<template>
    <div ref="propbox" v-touch:swipe.left="swipetoleft" v-touch:swipe.right="swipetoright" style="position: relative;"
         v-bind:style="imgheight">
        <div class="h-full w-14  z-10 absolute cursor-pointer p-2 text-green-200 text-3xl right-5"
             @mouseover="preload('right')"
             @click.prevent="swipe('right')">
            <div
                class="hover:text-green-400 hover:text-opacity-100 hover:bg-opacity-100 bg-opacity-50 bg-gray-400 p-1 rounded-full top-1/2 icon-circle-right absolute"></div>
        </div>
        <div class="h-full w-14  z-10 absolute cursor-pointer p-2 text-green-200 text-3xl left-5"
             @mouseover="preload('left')"
             @click.prevent="swipe('left')">
            <div
                class="hover:text-green-400 hover:text-opacity-100 hover:bg-opacity-100 bg-opacity-50 bg-gray-400 p-1 rounded-full top-1/2 icon-circle-left absolute"></div>
        </div>
        <transition-group class="relative flex justify-center" mode="out-in" name="property" tag="div"
                          v-on:after-enter="clearHeight">
            <div v-for="number in [pointer]" :key='number'>
                <picture>
                    <source v-if="safari == false" type="image/webp"
                            :srcset="webpSrcset"
                            sizes="(max-width: 767px) 400px, (max-width: 1279px) 768px, 1024px">
                    <img ref="currentimg" :alt="(currentTitle && currentTitle.length !== 0) ? currentTitle : alt + (absolutePointer+1) "
                         :src="currentElement"
                         :srcset="currentsrcset"
                         width="100%"
                         :height="currentHeight"
                         sizes="(max-width: 767px) 400px, (max-width: 1279px) 768px, 1024px"
                         @click="togglePhotoswipe(number)" @load="imageload">
                </picture>


                <span
                    class="font-nova-regular text-xl text-center absolute text-white z-20 left-0 h-8 w-full -bottom-0 text-shadow hover:opacity-0">{{
                        currentTitle
                    }}</span>
            </div>
        </transition-group>

        <photo-swipe-component v-if="showPhotoswipe" :imageIndex="selectedImage"
                               :images="photoswipeImages"></photo-swipe-component>
        <template v-if="prefetch">
            <img v-if="currentsrc !== 0" :src="preloadsrcset" class="" style="display: none;">
            <link v-for="n in prefetchlength" :href="prefetchsrcset(n)" :rel="prefetchSupported" as="image">
        </template>

    </div>
</template>

<script>
import {mutations, store} from "./store";

export default {
    name: "PropertySliderComponent",
    props: ['images', 'pid', 'alt'],
    components: {
        PhotoSwipeComponent: () => import('./PhotoSwipeComponent.vue')
    },
    data() {
        return {
            pointer: 0,
            preloadpointer: 1,
            imgheight: {},
            currentsrc: 0,
            currentType: 'jpg',
            currentHeight: "300",
            photoswipeImages: [],
            selectedImage: 0,
            imageDomain: import.meta.env.VITE_PROPERTY_IMAGE_DOMAIN,
            safari: false,
            prefetch: false
        }
    },
    created() {

        if (this.images.length !== 0) {
            this.images.forEach((item) => {
                let image = this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + item.pi_aid + ".jpg";
                let height = item.pi_h;
                let width = item.pi_w;
                let note = item.pi_note;
                let obj = {src: image, w: width, h: height, title: note};
                this.photoswipeImages.push(obj)
            });
        }
    },
    mounted() {
        this.safari = this.isSafari();
    },
    methods: {
        swipe(data) { //swipe the image left or right
            this.setHeight();
            if (data === 'right') {
                this.pointer += 1;
                this.currentImg += 1;
            }
            if (data === 'left') {
                this.pointer -= 1;
                this.currentImg -= 1;
            }
            this.preload(data);
        },
        swipetoright() { //touch event to nav left
            this.swipe('left');
        },
        swipetoleft() { //touch event to nav right
            this.swipe('right');
        },
        imageload() { // get the srcset of the current image for the preload
            if (this.$refs.currentimg[0].currentSrc.includes('_400.')) {
                this.currentsrc = "_400";
                this.currentHeight = "300";
            } else if (this.$refs.currentimg[0].currentSrc.includes('_768x576.')) {
                this.currentsrc = "_768x576";
                this.currentHeight = "576";
            } else if (this.$refs.currentimg[0].currentSrc.includes('_1024.')) {
                this.currentsrc = "_1024";
                this.currentHeight = "768";
            }


            if (this.$refs.currentimg[0].currentSrc.endsWith('.jpg')) {
                this.currentType = 'jpg';
            } else if (this.$refs.currentimg[0].currentSrc.endsWith('.webp')) {
                this.currentType = "webp";
            }


        },
        setHeight() { // stops div collapsing on swipe
            let heightString = this.$refs.propbox.clientHeight + 'px';
            Vue.set(this.imgheight, 'height', heightString);
        },
        clearHeight() {  // restores auto height after swipe
            Vue.set(this.imgheight, 'height', 'auto');
        },
        preload(data) { // preloads next image on hover of next button
            this.prefetch = true; // enable prefetch when interacting with slider
            this.preloadpointer = this.pointer;
            if (data === 'right') {
                this.preloadpointer += 1;
            }
            if (data === 'left') {
                this.preloadpointer -= 1;
            }
        },
        togglePhotoswipe(data) {
            this.selectedImage = Math.abs(data) % this.images.length;
            mutations.togglePhotoswipe()
        },
        prefetchsrcset(pointer) { // image to preload
            return this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[pointer].pi_aid + this.currentsrc + "." + this.currentType;
        },
        isSafari() {
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        }
    },
    computed: {
        absolutePointer() {
            return Math.abs(this.pointer) % this.images.length;
        },
        currentTitle() {
            return (this.images.length === 0 ? 'Awaiting images' : this.images[this.absolutePointer].pi_note)
        },
        currentElement() { // default for browsers without src set
            return (this.images.length === 0
                    ? 'https://www.simplyowners.net/images/awaiting_image.png'
                    : this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[this.absolutePointer].pi_aid + ".jpg"
            )
        },
        currentsrcset() { // src set image
            return (this.images.length === 0
                    ? 'https://www.simplyowners.net/images/awaiting_image.png'
                    : this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[this.absolutePointer].pi_aid + "_400.jpg 400w," +
                    this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[this.absolutePointer].pi_aid + "_768x576.jpg 768w, "
                    + this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[this.absolutePointer].pi_aid + "_1024.jpg 1024w"
            )
        },
        webpSrcset() { // src set image
            return (this.images.length === 0
                    ? 'https://www.simplyowners.net/images/awaiting_image.png'
                    : this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[this.absolutePointer].pi_aid + "_400.webp 400w," +
                    this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[this.absolutePointer].pi_aid + "_768x576.webp 768w, "
                    + this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[this.absolutePointer].pi_aid + "_1024.webp 1024w"
            )
        },
        preloadsrcset() { // image to preload xxx
            return (this.images.length === 0
                    ? 'https://www.simplyowners.net/images/awaiting_image.png'
                    : this.imageDomain + "/properties/" + this.pid + "/" + this.pid + "_" + this.images[Math.abs(this.preloadpointer) % this.images.length].pi_aid + this.currentsrc + "." + this.currentType
            )
        },

        showPhotoswipe() {
            return store.state.showPhotoswipe
        },
        prefetchlength() {
            if (this.images.length === 0) {
                return 0
            }

            if (this.currentsrc !== 0) {
                return this.images.length - 1
            }
        },
        prefetchSupported() {
            var relList = document.createElement('link').relList;

            return (!!(relList && relList.supports && relList.supports('prefetch'))) ? 'prefetch' : 'preload'
        }
    }
}
</script>

